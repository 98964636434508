import { analytics } from './firebase';
import { logEvent as logEventFirebase } from 'firebase/analytics';

export enum Events {
  SEND_MESSAGE = 'send_message',
  CREATE_CONVERSATION = 'create_conversation',
  ASK_QUESTION_BUTTON = 'ask_question_button_clicked',
  ABOUT_US_BUTTON = 'about_us_button_clicked',
  SWITCH_TO_SISTANI_BUTTON = 'switch_to_sistani_button_clicked',
  SWITCH_TO_KHAMENEI_BUTTON = 'switch_to_khamenei_button_clicked',
  CONTACT_US_BUTTON = 'contact_us_button_clicked',
  HOME_BUTTON = 'home_button_clicked',
}

const logEventWrapper = (
  eventName: string,
  eventParams: { [key: string]: any },
) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(eventName, eventParams);
    return;
  }
  logEventFirebase(analytics, eventName, eventParams);
};

export const logEvent = (eventName: Events) => {
  logEventWrapper(eventName, {});
};
