export type SOURCE = 'Sistani' | 'Khamenei';

interface ISource {
  text: string;
  url?: string;
  source?: string;
}

export interface IMessage {
  sources?: ISource[];
  queries?: string[];
  logic?: string;
  content: string;
  role: 'user' | 'assistant';
}

export const BASE_URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : '';
