export const HomeSVG = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 22L2 22"
        stroke="#001740"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M2 11L6.06296 7.74968M22 11L13.8741 4.49931C12.7784 3.62279 11.2216 3.62279 10.1259 4.49931L9.34398 5.12486"
        stroke="#001740"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15.5 5.5V3.5C15.5 3.22386 15.7239 3 16 3H18.5C18.7761 3 19 3.22386 19 3.5V8.5"
        stroke="#001740"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4 22V9.5"
        stroke="#001740"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20 9.5V13.5M20 22V17.5"
        stroke="#001740"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15 22V17C15 15.5858 15 14.8787 14.5607 14.4393C14.1213 14 13.4142 14 12 14C10.5858 14 9.87868 14 9.43934 14.4393M9 22V17"
        stroke="#001740"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 9.5C14 10.6046 13.1046 11.5 12 11.5C10.8954 11.5 10 10.6046 10 9.5C10 8.39543 10.8954 7.5 12 7.5C13.1046 7.5 14 8.39543 14 9.5Z"
        stroke="#001740"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const ArrowSVG = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99335 5.0018C9.76166 5.00135 9.53713 5.08254 9.35872 5.2313C9.25831 5.31506 9.17532 5.41793 9.11448 5.53402C9.05364 5.65011 9.01616 5.77714 9.00419 5.90782C8.99221 6.03851 9.00598 6.17029 9.0447 6.29562C9.08341 6.42094 9.14632 6.53735 9.22981 6.63818L13.6722 11.9863L9.38847 17.3444C9.3061 17.4465 9.24459 17.5639 9.20747 17.69C9.17036 17.8161 9.15837 17.9482 9.17219 18.079C9.18601 18.2097 9.22537 18.3364 9.28802 18.4518C9.35066 18.5672 9.43535 18.669 9.53721 18.7513C9.63981 18.8421 9.75996 18.9107 9.89012 18.9526C10.0203 18.9945 10.1576 19.0088 10.2936 18.9948C10.4295 18.9807 10.5611 18.9385 10.6801 18.8709C10.7991 18.8032 10.9029 18.7116 10.985 18.6016L15.7744 12.6149C15.9203 12.4364 16 12.2124 16 11.9813C16 11.7502 15.9203 11.5263 15.7744 11.3477L10.8164 5.36101C10.7169 5.24026 10.5905 5.14481 10.4475 5.0824C10.3045 5.01999 10.1489 4.99238 9.99335 5.0018Z"
        fill="#001740"
      />
    </svg>
  );
};

export const WhiteArrowSVG = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99335 5.0018C9.76166 5.00135 9.53713 5.08254 9.35872 5.2313C9.25831 5.31506 9.17532 5.41793 9.11448 5.53402C9.05364 5.65011 9.01616 5.77714 9.00419 5.90782C8.99221 6.03851 9.00598 6.17029 9.0447 6.29562C9.08341 6.42094 9.14632 6.53735 9.22981 6.63818L13.6722 11.9863L9.38847 17.3444C9.3061 17.4465 9.24459 17.5639 9.20747 17.69C9.17036 17.8161 9.15837 17.9482 9.17219 18.079C9.18601 18.2097 9.22537 18.3364 9.28802 18.4518C9.35066 18.5672 9.43535 18.669 9.53721 18.7513C9.63981 18.8421 9.75996 18.9107 9.89012 18.9526C10.0203 18.9945 10.1576 19.0088 10.2936 18.9948C10.4295 18.9807 10.5611 18.9385 10.6801 18.8709C10.7991 18.8032 10.9029 18.7116 10.985 18.6016L15.7744 12.6149C15.9203 12.4364 16 12.2124 16 11.9813C16 11.7502 15.9203 11.5263 15.7744 11.3477L10.8164 5.36101C10.7169 5.24026 10.5905 5.14481 10.4475 5.0824C10.3045 5.01999 10.1489 4.99238 9.99335 5.0018Z"
        fill="#FFFFFF"
      />
    </svg>
  );
};

export const SendMessageSVG = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.3938 2.20468C3.70395 1.96828 4.12324 1.93374 4.4679 2.1162L21.4679 11.1162C21.7953 11.2895 22 11.6296 22 12C22 12.3704 21.7953 12.7105 21.4679 12.8838L4.4679 21.8838C4.12324 22.0662 3.70395 22.0317 3.3938 21.7953C3.08365 21.5589 2.93922 21.1637 3.02382 20.7831L4.97561 12L3.02382 3.21692C2.93922 2.83623 3.08365 2.44109 3.3938 2.20468ZM6.80218 13L5.44596 19.103L16.9739 13H6.80218ZM16.9739 11H6.80218L5.44596 4.89699L16.9739 11Z"
        fill="#B5BBC4"
      />
    </svg>
  );
};

export const MenuSVG = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 12C1 11.4477 1.44772 11 2 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H2C1.44772 13 1 12.5523 1 12Z"
        fill="#0F0F0F"
      />
      <path
        d="M1 4C1 3.44772 1.44772 3 2 3H22C22.5523 3 23 3.44772 23 4C23 4.55228 22.5523 5 22 5H2C1.44772 5 1 4.55228 1 4Z"
        fill="#0F0F0F"
      />
      <path
        d="M1 20C1 19.4477 1.44772 19 2 19H22C22.5523 19 23 19.4477 23 20C23 20.5523 22.5523 21 22 21H2C1.44772 21 1 20.5523 1 20Z"
        fill="#0F0F0F"
      />
    </svg>
  );
};

export const CloseSVG = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Menu / Close_MD">
        <path
          id="Vector"
          d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const NewChatSVG = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.85113 3 4 5.73396 4 10C4 11.5704 4.38842 12.7289 5.08252 13.6554C5.79003 14.5998 6.87746 15.3863 8.41627 16.0908L9.2326 16.4645L8.94868 17.3162C8.54129 18.5384 7.84997 19.6611 7.15156 20.5844C9.56467 19.8263 12.7167 18.6537 14.9453 17.1679C17.1551 15.6948 18.3969 14.5353 19.0991 13.455C19.7758 12.4139 20 11.371 20 10C20 5.73396 16.1489 3 12 3ZM2 10C2 4.26604 7.14887 1 12 1C16.8511 1 22 4.26604 22 10C22 11.629 21.7242 13.0861 20.7759 14.545C19.8531 15.9647 18.3449 17.3052 16.0547 18.8321C13.0781 20.8164 8.76589 22.2232 6.29772 22.9281C5.48665 23.1597 4.84055 22.6838 4.56243 22.1881C4.28848 21.6998 4.22087 20.9454 4.74413 20.3614C5.44439 19.5798 6.21203 18.5732 6.72616 17.4871C5.40034 16.7841 4.29326 15.9376 3.48189 14.8545C2.48785 13.5277 2 11.9296 2 10Z"
        fill="#0F0F0F"
      />
      <path
        d="M12 6C11.4477 6 11 6.44771 11 7V9H9C8.44772 9 8 9.44771 8 10C8 10.5523 8.44772 11 9 11H11V13C11 13.5523 11.4477 14 12 14C12.5523 14 13 13.5523 13 13V11H15C15.5523 11 16 10.5523 16 10C16 9.44772 15.5523 9 15 9H13V7C13 6.44771 12.5523 6 12 6Z"
        fill="#0F0F0F"
      />
    </svg>
  );
};

export const SwitchSVG = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.70711 4.70711C9.09763 4.31658 9.09763 3.68342 8.70711 3.29289C8.31658 2.90237 7.68342 2.90237 7.29289 3.29289L3.29289 7.29289C2.90237 7.68342 2.90237 8.31658 3.29289 8.70711L7.29289 12.7071C7.68342 13.0976 8.31658 13.0976 8.70711 12.7071C9.09763 12.3166 9.09763 11.6834 8.70711 11.2929L6.41421 9H16C16.5523 9 17 8.55228 17 8C17 7.44772 16.5523 7 16 7H6.41421L8.70711 4.70711ZM20.7071 15.2929L16.7071 11.2929C16.3166 10.9024 15.6834 10.9024 15.2929 11.2929C14.9024 11.6834 14.9024 12.3166 15.2929 12.7071L17.5858 15H8C7.44772 15 7 15.4477 7 16C7 16.5523 7.44772 17 8 17H17.5858L15.2929 19.2929C14.9024 19.6834 14.9024 20.3166 15.2929 20.7071C15.6834 21.0976 16.3166 21.0976 16.7071 20.7071L20.7071 16.7071C21.0976 16.3166 21.0976 15.6834 20.7071 15.2929Z"
        fill="#000000"
      />
    </svg>
  );
};

export const ThumbsDownSVG = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Complete">
        <g id="thumbs-down">
          <path
            d="M7.3,12.6,10.1,21a.6.6,0,0,0,.8.3l1-.5a2.6,2.6,0,0,0,1.4-2.3V14.6h6.4a2,2,0,0,0,1.9-2.5l-2-8a2,2,0,0,0-1.9-1.5H4.3a2,2,0,0,0-2,2v6a2,2,0,0,0,2,2h3V2.6"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export const ThumbsUpSVG = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Complete">
        <g id="thumbs-up">
          <path
            d="M7.3,11.4,10.1,3a.6.6,0,0,1,.8-.3l1,.5a2.6,2.6,0,0,1,1.4,2.3V9.4h6.4a2,2,0,0,1,1.9,2.5l-2,8a2,2,0,0,1-1.9,1.5H4.3a2,2,0,0,1-2-2v-6a2,2,0,0,1,2-2h3v10"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
};

export const WaveSVG = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 974 614"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4_62)">
        <path d="M0 0H974V614H0V0Z" fill="#E4B290" />
        <path
          d="M974 300.66L919.923 307.219C865.744 313.778 757.589 326.897 649.333 306.694C541.077 286.754 432.923 233.229 324.667 227.72C216.411 221.947 108.256 263.927 54.0773 284.917L-1.52588e-05 305.907V431.847H54.0773C108.256 431.847 216.411 431.847 324.667 431.847C432.923 431.847 541.077 431.847 649.333 431.847C757.589 431.847 865.744 431.847 919.923 431.847H974V300.66Z"
          fill="#EBC6AD"
        />
        <path
          d="M974 402.993L919.923 409.552C865.744 416.112 757.589 429.23 649.333 409.028C541.077 389.087 432.923 335.563 324.667 330.053C216.411 324.281 108.256 366.261 54.0773 387.251L-1.52588e-05 408.241V534.18H54.0773C108.256 534.18 216.411 534.18 324.667 534.18C432.923 534.18 541.077 534.18 649.333 534.18C757.589 534.18 865.744 534.18 919.923 534.18H974V402.993Z"
          fill="#F3DBCC"
        />
        <path
          d="M974 513.713L919.923 518.83C865.744 523.947 757.589 534.18 649.333 518.421C541.077 502.866 432.923 461.114 324.667 456.816C216.411 452.313 108.256 485.06 54.0773 501.433L-1.52588e-05 517.807V616.047H54.0773C108.256 616.047 216.411 616.047 324.667 616.047C432.923 616.047 541.077 616.047 649.333 616.047C757.589 616.047 865.744 616.047 919.923 616.047H974V513.713Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export const WaveSVGLarge = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 4202 454"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5_67)">
        <path d="M0 0H4202V454H0V0Z" fill="#E4B290" />
        <path
          d="M4202 167.804L3968.7 174.214C3734.97 180.624 3268.37 193.443 2801.33 173.701C2334.3 154.215 1867.7 101.911 1400.67 96.5273C933.632 90.8867 467.035 131.909 233.299 152.421L0 172.932V296H233.299C467.035 296 933.632 296 1400.67 296C1867.7 296 2334.3 296 2801.33 296C3268.37 296 3734.97 296 3968.7 296H4202V167.804Z"
          fill="#EBC6AD"
        />
        <path
          d="M4202 267.804L3968.7 274.214C3734.97 280.624 3268.37 293.443 2801.33 273.701C2334.3 254.215 1867.7 201.911 1400.67 196.527C933.632 190.887 467.035 231.909 233.299 252.421L3.05176e-05 272.932V396H233.299C467.035 396 933.632 396 1400.67 396C1867.7 396 2334.3 396 2801.33 396C3268.37 396 3734.97 396 3968.7 396H4202V267.804Z"
          fill="#F3DBCC"
        />
        <path
          d="M4202 379.847L3968.7 383.63C3734.97 387.413 3268.37 394.98 2801.33 383.327C2334.3 371.826 1867.7 340.954 1400.67 337.776C933.632 334.447 467.035 358.66 233.299 370.767L3.05176e-05 382.873V455.513H233.299C467.035 455.513 933.632 455.513 1400.67 455.513C1867.7 455.513 2334.3 455.513 2801.33 455.513C3268.37 455.513 3734.97 455.513 3968.7 455.513H4202V379.847Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
