import { useState, useCallback } from 'react';
import { SOURCE } from './types';
import { Events, logEvent } from '../../utils/logging/events';

export const useSource = () => {
  const [source, setSource] = useState<SOURCE>('Sistani');

  const switchSource = useCallback(() => {
    setSource((prev) => {
      if (prev === 'Sistani') {
        logEvent(Events.SWITCH_TO_KHAMENEI_BUTTON);
        return 'Khamenei';
      } else {
        logEvent(Events.SWITCH_TO_SISTANI_BUTTON);
        return 'Sistani';
      }
    });
  }, []);

  return {
    source,
    switchSource,
  };
};
