import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './pages/AboutPage';
import Chatbox from './pages/chatbox/Chatbox';
import FirstPage from './pages/FirstPage';
import { ChatboxHeaders } from './utils/seo';
// const BetaBanner = () => {
//   return (
//     <div className="w-full bg-[#FFD7A7] text-[#0D192D] min-h-[5svh] flex-col flex justify-around">
//       <div className="h-fit w-fit mx-auto">We're still in Beta!</div>
//     </div>
//   );
// };

export default function App() {
  return (
    <Router>
      <div className="h-full">
        <div className="flex md:gap-10 gap-6 justify-between flex-col overflow-x-hidden text-center h-[100svh]">
          <Routes>
            <Route index element={<FirstPage />} />
            <Route
              path="ask"
              element={
                <div className="min-h-[100%] xl:p-10">
                  <ChatboxHeaders />
                  <Chatbox disabled={false} />
                </div>
              }
            />
            {/* <Route
              path="ask-hidden"
              element={
                <div className="min-h-[100%] xl:p-10">
                  <ChatboxHeaders />
                  <Chatbox disabled={false}/>
                </div>
              }
            /> */}
            <Route path="about" element={<About />} />
            <Route path="*" element={<div></div>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}
