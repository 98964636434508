// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCt0UMkBsIzTbV0gug26zbZDMGosOPDM5Q',
  authDomain: 'marjaai.firebaseapp.com',
  projectId: 'marjaai',
  storageBucket: 'marjaai.appspot.com',
  messagingSenderId: '594153680998',
  appId: '1:594153680998:web:6876980e1273d47bfb7da7',
  measurementId: 'G-J2VMCVY8G8',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };
