import React, { useState } from 'react';
import {
  MenuSVG,
  CloseSVG,
  NewChatSVG,
  SendMessageSVG,
  SwitchSVG,
  ThumbsDownSVG,
  ThumbsUpSVG,
  HomeSVG,
} from '../../utils/logos';
import { TextWithLineBreaks } from '../../utils/TextSplitter';
import { Collapsible } from '../../utils/Collapsible';
import { useSource } from './useSource';
import { useConversation } from './useConversation';
import { BASE_URL, IMessage } from './types';
import { Events, logEvent } from '../../utils/logging/events';

interface IChatboxProps {
  disabled?: boolean;
}

const Chatbox: React.FC<IChatboxProps> = ({ disabled }) => {
  const { source } = useSource();
  const { conversationId, updateConversationId } = useConversation(source);

  const [messages, setMessages] = useState<IMessage[]>([]);
  const [message, setMessage] = useState('');

  const scrollToEnd = React.useCallback(() => {
    const el = document.getElementById('last_div');
    if (!el || messages.length < 2) return;
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }, [messages]);

  const [sistaniFeedback, setSistaniFeedback] = useState<'good' | 'bad' | null>(
    null,
  );
  const [KhameneiFeedback, setKhameneiFeedback] = useState<
    'good' | 'bad' | null
  >(null);

  const newChat = React.useCallback(() => {
    sessionStorage.removeItem('conversationId' + source);
    setMessages([]);
    updateConversationId();
    setIsMenuOpen(false);
    if (source === 'Sistani') setSistaniFeedback(null);
    if (source === 'Khamenei') setKhameneiFeedback(null);
  }, [source, updateConversationId]);

  const updateMessages = React.useCallback(() => {
    if (conversationId === null) return;
    fetch(`${BASE_URL}/messages?conversation_id=${conversationId}`)
      .then((res) => {
        if (res.status === 200) {
          res.json().then((data) => {
            setMessages(
              (data as any[]).map((d) => ({
                content: d.content,
                role: d.role,
                sources: d.sources,
                queries: d.queries,
                logic: d.logic,
              })),
            );
          });
        } else {
          newChat();
        }
      })
      .catch((_) => newChat());
  }, [conversationId, newChat]);

  const autoResizeTextarea = React.useCallback(() => {
    const textarea = document.getElementById('my-input');
    if (!textarea) return;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, []);

  React.useEffect(() => updateMessages(), [conversationId, updateMessages]);

  React.useEffect(() => {
    updateConversationId();
  }, [updateConversationId, source]);

  React.useEffect(() => {
    autoResizeTextarea();
  }, [message, autoResizeTextarea, source]);

  const sendMessage = React.useCallback(() => {
    if (
      message === '' ||
      (messages.length > 0 && messages[messages.length - 1].role === 'user')
    )
      return;
    setMessages((prev) => [...prev, { content: message, role: 'user' }]);
    const body = JSON.stringify({
      marjaa: source,
      conversation_id: conversationId,
      message,
    });
    logEvent(Events.SEND_MESSAGE);
    fetch(`${BASE_URL}/chatbot`, {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.status === 200) {
        setMessage('');
        updateMessages();
      }
      if (res.status === 429) {
        setMessages((prev) => [
          ...prev,
          {
            content:
              'MashAllah, too many messages in a row! Try again in a few minutes.',
            role: 'assistant',
          },
        ]);
        setMessage('');
      }
    });
  }, [conversationId, message, messages, updateMessages, source]);

  const sendFeedback = React.useCallback(
    (feedback: 'good' | 'bad') => {
      if (conversationId === null) return;
      if (source === 'Sistani') setSistaniFeedback(feedback);
      if (source === 'Khamenei') setKhameneiFeedback(feedback);
      const body = JSON.stringify({
        conversation_id: conversationId,
        feedback,
      });
      fetch(`${BASE_URL}/feedback`, {
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.status === 200) {
        }
      });
      setIsMenuOpen(false);
    },
    [conversationId, source],
  );

  const sendGoodFeedback = React.useCallback(
    () => sendFeedback('good'),
    [sendFeedback],
  );
  const sendBadFeedback = React.useCallback(
    () => sendFeedback('bad'),
    [sendFeedback],
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const switchSourceHandler = React.useCallback(() => {
  // 	switchSource();
  // 	setIsMenuOpen(false);
  // }, [switchSource]);

  const currentFeedback =
    source === 'Sistani' ? sistaniFeedback : KhameneiFeedback;

  const menuClassName =
    'bg-yellow3 h-fit py-6 px-4 rounded-xl hover:cursor-pointer text-blue3 w-[24rem] max-w-[80vw] mx-auto flex text-left gap-2 hover:bg-yellow1 items-center';

  React.useEffect(() => scrollToEnd(), [messages, scrollToEnd]);

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      sendMessage();
    } else if (e.key === 'Enter' && e.shiftKey) {
      const textarea = e.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const value = textarea.value;
      const newValue = value.substring(0, start) + value.substring(end);
      setMessage(newValue);
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    }
  };

  const FeedbackButtons: React.FC<{
    currentFeedback: string | null;
    sendGoodFeedback: () => void;
    sendBadFeedback: () => void;
  }> = ({ currentFeedback, sendGoodFeedback, sendBadFeedback }) => {
    return (
      <div
        className={`flex gap-2 mt-auto h-fit mb-4 stroke-black items-center ${currentFeedback === null ? 'justify-center' : 'justify-start'}`}
      >
        {currentFeedback !== 'bad' && (
          <div
            className={`${currentFeedback === 'good' ? 'bg-green1 stroke-white' : 'bg-yellow3'} w-fit h-fit rounded-full p-2 hover:cursor-pointer`}
            onClick={sendGoodFeedback}
          >
            <ThumbsUpSVG />
          </div>
        )}
        {currentFeedback !== 'good' && (
          <div
            className={`${currentFeedback === 'bad' ? 'bg-red1 stroke-blue1' : 'bg-yellow3'} w-fit h-fit rounded-full p-2 hover:cursor-pointer`}
            onClick={sendBadFeedback}
          >
            <ThumbsDownSVG />
          </div>
        )}
      </div>
    );
  };

  const Message: React.FC<{ message: IMessage }> = ({ message }) => {
    if (disabled === true) {
      message.content =
        "Salam! Our AI assistant is currently under maintenance, we are adding new books and QnA to it! We expect to be back up by Friday insha'Allah.";
    }
    message.sources?.forEach((source) => {
      if (source.source !== "Sistani's Q & A") return;
      source.text = source.text.replace(' Answer: ', '\n\nAnswer: ');
    });
    return (
      <div
        className={`${
          message.role === 'user'
            ? 'ml-auto bg-[#3A7CA5]'
            : 'mr-auto bg-gray-500'
        } max-w-[85%] p-4 rounded-2xl message fade-in break-words flex flex-col gap-4`}
      >
        {message.sources !== undefined && message.sources.length > 0 && (
          <div className="italic bold text-sm">
            Disclaimer: Answers are AI-generated. While we strive for accuracy,
            errors may occur. Please always refer to listed sources, the
            official website of your Marja', or consult a scholar.
          </div>
        )}
        <TextWithLineBreaks text={message.content} />
        {message.logic !== undefined && message.logic !== '' && (
          <Collapsible title="Logic used">
            <TextWithLineBreaks text={message.logic} />
          </Collapsible>
        )}
        {message.sources !== undefined && message.sources.length > 0 && (
          <Collapsible title="Sources and extracts used">
            <div>
              {message.sources.map((s, i) => (
                <div key={i}>
                  <div className="italic pb-4 underline underline-offset-8">
                    Source {i + 1}
                    {s.source != null ? ' - ' + s.source + ' ' : ' '}
                    {s.url != null && (
                      <a
                        className="hover:underline"
                        target="_blank"
                        href={
                          s.url.includes('://') ? s.url : 'https://' + s.url
                        }
                        rel="noreferrer"
                      >
                        (view original)
                      </a>
                    )}
                  </div>
                  <TextWithLineBreaks text={s.text} key={i} />
                  <br />
                </div>
              ))}
            </div>
          </Collapsible>
        )}
      </div>
    );
  };

  return (
    <div className="h-full w-full flex flex-col px-4 xl:px-8 gap-2 pt-4 border-0 border-yellow3 rounded-2xl xl:border-4 relative">
      <div className="flex gap-2 items-center border-b-[1px] pb-4">
        <img
          src="/logomarjaai.png"
          alt="background"
          className="h-10 xl:hidden"
        />
        <div className="text-lg">Ayatollah {source}</div>
        <div className="flex gap-2 ml-auto flex-wrap-reverse z-20">
          <div
            className="bg-yellow3 h-fit py-2 px-4 rounded-lg hover:cursor-pointer ml-auto"
            onClick={() => setIsMenuOpen((p) => !p)}
          >
            {!isMenuOpen ? <MenuSVG /> : <CloseSVG />}
          </div>
        </div>
      </div>
      {isMenuOpen ? (
        <div className="absolute inset-0 items-center justify-center flex z-10">
          <div className="flex-col flex gap-6 my-auto mx-auto p-6 rounded-xl">
            <a
              className={menuClassName}
              href="/"
              onClick={() => logEvent(Events.HOME_BUTTON)}
            >
              <HomeSVG />
              Home page
            </a>
            <div className={menuClassName} onClick={newChat}>
              <NewChatSVG />
              New chat
            </div>
            <div
              className={
                menuClassName +
                ' opacity-50 hover:bg-yellow3 hover:cursor-not-allowed'
              }
            >
              <SwitchSVG /> Switch to{' '}
              {source === 'Sistani'
                ? 'other Ayatollahs (coming soon)'
                : 'Ayatollah Sistani'}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="overflow-scroll py-4 flex flex-col text-left gap-6 flex-grow">
            {messages.map((message, i) => (
              <Message key={i} message={message} />
            ))}
            {messages.length % 2 === 0 && (
              <div className="bouncing-loader mr-auto bg-gray-500 p-4 rounded-2xl message fade-in">
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
            <div id="last_div" className="h-1"></div>
          </div>
          {currentFeedback === null && messages.length > 2 && (
            <div className="text-yellow3 text-center">How was the answer?</div>
          )}
          {messages.length > 2 && (
            <FeedbackButtons
              sendBadFeedback={sendBadFeedback}
              sendGoodFeedback={sendGoodFeedback}
              currentFeedback={currentFeedback}
            />
          )}
          <div className="mt-auto h-fit border-2 border-gray1 rounded-lg flex justify-between gap-6 px-4 py-2">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                sendMessage();
              }}
              className="h-full w-full"
            >
              <textarea
                placeholder="What would you like to know?"
                className="bg-transparent border-none focus:outline-none w-full mt-[6px] resize-none disabled:opacity-50"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                id="my-input"
                rows={1}
                disabled={disabled || messages.length % 2 === 0}
                onKeyDown={(e) => handleKeyDown(e)}
              />
            </form>
            <div className="my-auto cursor-pointer" onClick={sendMessage}>
              <SendMessageSVG />
            </div>
          </div>
          <div className="text-gray1 text-xs mx-auto mb-3 xl:mb-6 mt-3 text-center">
            Disclaimer: Answers are AI-generated. While we strive for accuracy,
            errors may occur. Please always refer to listed sources, the
            official website of your Marja', or consult a scholar.
          </div>
        </>
      )}
    </div>
  );
};

export default Chatbox;
