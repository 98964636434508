import React from 'react';
import { Events, logEvent } from '../utils/logging/events';
import { FooterPatterned } from './shared/Footer';

const Description: React.FC = () => {
  return (
    <div className="bg-white max-w-96 h-fit mx-auto px-10 py-16 md:px-16 rounded-3xl leading-normal text-sm md:text-base flex flex-col md:flex-row gap-8 justify-between items-center">
      <img
        src="/man_bulb.png"
        alt="background"
        className="md:max-h-80 max-h-60 w-fit h-fit mx-auto"
      />
      <div className="flex flex-col gap-8">
        <div className="italic text-gray1 ">
          It has never been easier to ask your Shia Islamic jurisprudence
          questions.
        </div>
        <div className="font-semibold text-blue3">
          Engage in meaningful conversations, find answers and deepen your
          understanding
        </div>
      </div>
    </div>
  );
};

const AskQuestionButton: React.FC = () => {
  return (
    <h3 className="w-fit mx-auto">
      <div className="leading-normal text-blue1 hover:cursor-pointer">
        <a
          onClick={() => logEvent(Events.ASK_QUESTION_BUTTON)}
          className="px-16 py-5 bg-yellow3 w-fit rounded-[5rem] flex font-medium text-lg"
          href="/ask"
        >
          Ask your question
        </a>
      </div>
    </h3>
  );
};

const Disclaimer: React.FC = () => {
  return (
    <div className="text-gray-500 text-sm md:max-w-[38rem] md:mx-auto tracking-wide">
      Disclaimer: Answers are AI-generated. While we strive for accuracy, errors
      may occur. Please always refer to listed sources, the official website of
      your Marja', or consult a scholar.
    </div>
  );
};

const FirstPage: React.FC = () => {
  return (
    <div className="my-auto mx-auto w-full md:leading-[6rem] leading-[4rem] z-20 !min-h-[100vh] overflow-y-auto overflow-x-hidden relative">
      {/* <div className="text-white md:text-[25rem] text-[15rem] md:p-[6rem] p-[3rem] absolute top-0 z-[-1] md:-left-[20rem] -left-[10rem] overflow-hidden">
        مرجع مرجع مرجع مرجع
      </div> */}
      <div className="lg:w-2/3 flex flex-col justify-start gap-12 mx-auto overflow-y-visible px-10 py-6 ">
        <a
          className="font-thin text-3xl my-4 md:mb-16 tracking-[0.5rem]"
          href="/"
        >
          MARJA<span className="text-yellow3">AI</span>
        </a>
        <img
          src="/logomarjaai.png"
          alt="background"
          className="md:h-36 h-20 mx-auto"
        />
        <h1 className="md:text-[3rem] text-[2rem] gap-2 md:leading-[5rem] leading-[2.7rem] font-bold">
          Effortlessly Explore
          <br />
          <span className="text-yellow3">Shia Islamic Law</span>
        </h1>
        <AskQuestionButton />
        <Disclaimer />
        <div className="md:mb-0 -mb-60 z-50 shadow-lg shadow-black rounded-3xl md:shadow-none">
          <Description />
        </div>
      </div>
      <FooterPatterned />
      {/* <div className="lg:block hidden w-1/3">
        <img src="/background-1.png" alt="background" className="top-0 bottom-0 right-0 absolute max-w-[40%] h-full object-cover object-left" />
      </div> */}
    </div>
  );
};

export default FirstPage;
