import { Events, logEvent } from '../../utils/logging/events';
import { WaveSVG, WaveSVGLarge } from '../../utils/logos';

export const Footer: React.FC = () => {
  return (
    <div className="bg-white text-blue2 w-full p-16 md:p-20 leading-normal">
      <div className="mx-auto flex md:flex-row flex-col md:gap-16 gap-4 justify-around items-start">
        <div className="items-center text-sm font-thin md:w-1/3">
          <div className="flex flex-col gap-1 items-start w-fit mx-auto">
            <div className="font-semibold pb-2 text-base tracking-wide">
              INFORMATION
            </div>
            <a
              onClick={() => logEvent(Events.CONTACT_US_BUTTON)}
              href="/about"
              className="hover:cursor-pointer underline-offset-2 hover:underline"
            >
              About us
            </a>
            <a
              onClick={() => logEvent(Events.CONTACT_US_BUTTON)}
              href="mailto:salam@marja.ai"
              className="hover:cursor-pointer underline-offset-2 hover:underline"
            >
              Join our team
            </a>
            <div className="opacity-50">Donate (coming soon)</div>
          </div>
        </div>
        <div className="md:h-10 md:w-0 w-full md:mt-10 my-6 border-[0.75px] border-blue1 opacity-25 mt:my-auto flex-shrink"></div>
        <div className="items-center text-sm font-thin md:w-1/3">
          <div className="flex flex-col gap-1 items-start w-fit mx-auto">
            <div className="font-semibold pb-2 text-base tracking-wide">
              CONTACT US
            </div>
            {/* <div>+44 xxxxxxxxxx</div> */}
            <a
              // onClick={() => logEvent(Events.CONTACT_US_BUTTON)}
              href="mailto:salam@marja.ai"
              className="hover:cursor-pointer underline-offset-2 hover:underline"
            >
              salam@marja.ai
            </a>
          </div>
        </div>
      </div>
      <div className="md:pt-20 pt-16 md:-mb-10">
        <div className="leading-loose font-thin tracking-wider md:text-xs text-[8px]">
          MARJAAI 2024. ALL RIGHTS RESERVED.
        </div>
      </div>
    </div>
  );
};

export const FooterPatterned: React.FC = () => {
  return (
    <div className="relative pt-20">
      <div className="w-screen relative hidden md:block">
        <WaveSVGLarge />
        <div className="w-screen bg-white h-10 z-50 -mt-10"></div>
      </div>
      <div className="w-screen relative md:hidden">
        <WaveSVG />
      </div>
      <Footer />
    </div>
  );
};
