import React from 'react';

export const TextWithLineBreaks: React.FC<{ text: string }> = ({ text }) => {
  const lines = text.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));

  return <div>{lines}</div>;
};

export default TextWithLineBreaks;
