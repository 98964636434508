import { FooterPatterned } from './shared/Footer';

// Placeholder for the About page
const About: React.FC = () => {
  return (
    <div className="my-auto mx-auto w-full md:leading-[6rem] leading-[4rem] z-20 !min-h-[100vh] overflow-y-auto overflow-x-hidden relative">
      <div className="lg:w-2/3 flex flex-col justify-start gap-6 md:gap-8 mx-auto overflow-y-visible px-10 py-6 ">
        <a
          className="font-thin text-3xl my-4 md:mb-16 tracking-[0.5rem]"
          href="/"
        >
          MARJA<span className="text-yellow3">AI</span>
        </a>
        <h1 className="md:text-[3rem] text-[2.5rem] gap-2 md:leading-[5rem] leading-[2.7rem]">
          Made by <span className="italic text-yellow3 font-bold">Shias</span>,
          for <span className="italic text-yellow3 font-bold">Shias</span>
        </h1>
        <div className="leading-loose md:pt-20">
          For years, believers have struggled with getting the right answers to
          their questions. Most of us never get answers to our questions, unless
          we know a scholar personally, have the patience to wait for a manual
          reply from the office, or are savvy enough to navigate the website and
          books. And that's without even mentioning the awkward questions that
          people prefer not to ask. That's why we built Marja Ai. We're a group
          of believers who wanted to take on the problem, head on. Marja Ai has
          been built under the guidance of scholars. Our primary objective is to
          increase access to Islamic rulings.
        </div>
      </div>
      <FooterPatterned />
    </div>
  );
};

export default About;
