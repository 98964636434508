import { useState, useCallback } from 'react';
import { BASE_URL, SOURCE } from './types';
import { Events, logEvent } from '../../utils/logging/events';

export const useConversation = (source: SOURCE) => {
  const [conversationId, setConversationId] = useState<string | null>(null);

  const updateConversationId = useCallback(() => {
    var conversationId = sessionStorage.getItem('conversationId' + source);
    if (conversationId === null) {
      logEvent(Events.CREATE_CONVERSATION);
      fetch(`${BASE_URL}/new_conversation?marjaa=${source}`).then((res) => {
        if (res.status === 200) {
          res.text().then((data) => {
            conversationId = data;
            sessionStorage.setItem('conversationId' + source, conversationId);
            setConversationId(conversationId);
          });
        }
      });
    } else {
      setConversationId(conversationId);
    }
  }, [source]);

  return {
    conversationId,
    updateConversationId,
  };
};
