import React, { useState } from 'react';
import { WhiteArrowSVG } from './logos';

export const Collapsible: React.FC<{
  title: string;
  children: React.JSX.Element;
}> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapsible = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full flex flex-col gap-4">
      <button
        className="flex items-center justify-between w-full font-bold underline underline-offset-8 focus:outline-none"
        onClick={toggleCollapsible}
      >
        <span>{title}</span>
        <div
          className={`transform transition-transform duration-300 ${
            isOpen ? 'rotate-90' : ''
          }`}
        >
          <WhiteArrowSVG />
        </div>
      </button>
      <div
        className={`overflow-hidden transition-max-height duration-300 ${
          isOpen ? 'max-h-full' : 'max-h-0'
        }`}
      >
        {children}
      </div>
    </div>
  );
};
